import { AppSettingsService } from './app-settings.service';
import { CompanyInfo } from '../models/admin/company-info.model';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { HttpRequestService } from './http-request.service';
import { Injectable } from '@angular/core';
import { JwtModel } from '../models/admin/jwt.model';
import { JwtService } from './jwt.service';
import { LanguageService } from './language.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PermissionUser, User } from '../models/admin/user.model';
import { jwtDecode } from 'jwt-decode';
import { Permission } from '../constant/Permission';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private USER_MODEL = 'user';
  private TOKEN_DECODE = 'token_decode';
  private REDIRECT_CCM = 'redirectCcm';
  private FILTER_DASHBOARD = 'filter';
  defaultAviPath = 'assets/img/avatars/default.png';

  constructor(
    private httpRequestService: HttpRequestService,
    private jwtService: JwtService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private domSanitizer: DomSanitizer,
  ) { }

  getUserInfo() {
    return this.httpRequestService.get<User>(this.appSettingsService.getAdminApi(), '/api/user/GetUserInfo');
  }

  signout(): void {
    const isUserFromCcm = this.isUserFromCcm();
    const params = new HttpParams()
      .set('token', this.jwtService.get().access_token)
      .set('client_id', isUserFromCcm ? 'hiperstream3' : 'datavalidationautomation')
      .set('client_secret', 'Dv4Ad1min2017*!')
      .set('type_credentials', 'client_credentials');
    this.httpRequestService
      .post<any>(this.appSettingsService.getProviderIdentity(), '/connect/revocation', params, false)
      .subscribe(() => {
        this.jwtService.delete();
        this.removeUser();
        this.removeFilter();
        window.location.href = this.appSettingsService.getHiperstream3();
      });
  }

  private removeUser(): void {
    localStorage.removeItem(this.USER_MODEL);
    localStorage.removeItem(this.REDIRECT_CCM);
  }

  private removeFilter() {
    localStorage.removeItem(this.FILTER_DASHBOARD);
  }

  signin(token: JwtModel): void {
    this.jwtService.save(token);
    this.removeFilter();
    this.updateUser();
  }

  private updateUser(): void {
    this.getUserInfo().subscribe((user) => {
      if (user) {
        this.updateUserInStorage(user);
        this.setLanguageDefaultByUser(user);
      }
    });
  }

  private updateUserInStorage(user: User) {
    localStorage.setItem(this.USER_MODEL, JSON.stringify(user));
  }

  private setLanguageDefaultByUser(user: User) {
    if (user.languageAlias) {
      this.setLanguageDefault(user.languageAlias);
    }
  }

  setLanguageDefault(language: string) {
    this.languageService.defaultLanguage.next(language);
    this.translateService.use(this.languageService.defaultLanguage.getValue());
  }

  getUser(): User {
    const user = localStorage.getItem(this.USER_MODEL);
    if (user) {
      let parsedUser = JSON.parse(user) as User;
      return parsedUser;
    } else {
      return null;
    }
  }

  getUserAvi() {
    const user = localStorage.getItem(this.USER_MODEL);
    if (user) {
      let parsedUser = JSON.parse(user) as User;
      if (!parsedUser.base64Avatar) return this.defaultAviPath;
      return this.domSanitizer.bypassSecurityTrustResourceUrl(parsedUser.base64Avatar);
    } else {
      return null;
    }
  }

  updateUserAvi(encodedFile) {
    const user = localStorage.getItem(this.USER_MODEL);
    if (user) {
      const parsedUser = JSON.parse(user) as User;
      parsedUser.base64Avatar = encodedFile;
      localStorage.setItem(this.USER_MODEL, JSON.stringify(parsedUser));
      return this.httpRequestService.put(this.appSettingsService.getAdminApi(), '/api/User/SaveAvatar', {
        base64Avatar: encodedFile,
      });
    } else {
      return null;
    }
  }

  getUserId() {
    const user = this.getUser();
    if (user != null) {
      return user.userId;
    }
    return null;
  }

  getCompanyId() {
    const user = this.getUser();
    if (user != null) {
      return user.companyId;
    }
    return null;
  }

  getCompanies() {
    const user = this.getUser();
    if (user != null) {
      return user.companies;
    }
    return null;
  }

  getToken() {
    const user = this.getUser();
    if (user != null) {
      return user.token;
    }
    return null;
  }

  private updateTokenDecodeInStorage(tokenDecode: string) {
    localStorage.setItem(this.TOKEN_DECODE, JSON.stringify(tokenDecode));
  }


  saveTokenDecode(tokenDecode: string) {
    if (tokenDecode) {
      this.updateTokenDecodeInStorage(tokenDecode);
    }
  }


  getRoleUser(): string {
    const token = localStorage.getItem(this.TOKEN_DECODE);
    if (token) {
      const parsedToken = JSON.parse(token);
      return parsedToken.Group
    }
    return null
  }


  hasPermissionForRoute(route: string): boolean {
    const pagePermission = Permission.pages.find((page) => page.path === route || route.startsWith('/policy-create-update'));
    if (!pagePermission) {
      return false;
    }
    return pagePermission.roles.some((role) => role === this.getRoleUser() || role === '*');

  }

  isUserFromCcm() {
    const user = this.getUser();
    if (user != null) {
      return user.hp3;
    }
    return this.isRedirectFromCcm();
  }

  setRedirectFromCcm() {
    localStorage.setItem(this.REDIRECT_CCM, 'true');
  }

  private isRedirectFromCcm() {
    const redirectCcm = localStorage.getItem(this.REDIRECT_CCM);
    if (redirectCcm) {
      return redirectCcm === 'true';
    }
    return false;
  }

  getLanguageId() {
    const user = this.getUser();
    if (user != null) {
      return user.languageId;
    }
    return null;
  }

  getLanguageAlias(): string {
    const user = this.getUser();
    if (user != null) {
      return user.languageAlias;
    }
    return null;
  }

  updateUserCompany(company: CompanyInfo) {
    const user = this.getUser();

    user.companyId = company.companyId;
    user.companyName = company.companyName;
    user.companyParentId = company.companyParentId;
    user.companyParentName = company.companyParentName;
    user.token = company.token;

    this.updateUserInStorage(user);
    this.removeFilter();

    window.location.reload();
  }
}
