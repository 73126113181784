import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '../@onboarding/services/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { HasRoleGuardService } from 'src/@onboarding/services/has-role-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        pathMatch: 'full',
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'settings/:section',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'policies',
        loadChildren: () => import('./pages/policies/policies.module').then((m) => m.PoliciesModule),
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'policy-create-update',
        loadChildren: () =>
          import('./pages/policies/policy-create-update/policy-create-update.module').then(
            (m) => m.PolicyCreateUpdateModule,
          ),
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'simulator',
        loadChildren: () => import('./pages/simulator/simulator.module').then((m) => m.SimulatorModule),
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'simulator-lote',
        loadChildren: () => import('./pages/simulator-lote/simulator-lote.module').then((m) => m.SimulatorLoteModule),
        canActivate: [HasRoleGuardService],
      },
      {
        path: 'historic-lote',
        loadChildren: () => import('./pages/historic-lote/historic-lote.module').then((m) => m.HistoricLoteModule),
        canActivate: [HasRoleGuardService],
      },
      { path: '**', redirectTo:''}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
