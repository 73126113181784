<div class="container-modal">
    <div class="flex-modal">
        <h3 mat-dialog-title>
            {{ title }}
        </h3>
        <mat-icon mat-button mat-dialog-close>close</mat-icon>
    </div>
    <div>
        <h4 class="modal-title"> {{ message }} </h4>
    </div>
</div>