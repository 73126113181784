import { CompanyInfo } from 'src/@onboarding/models/admin/company-info.model';
import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/@onboarding/services/language.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SecurityService } from 'src/@onboarding/services/security.service';

@Component({
  selector: 'onboarding-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
})
export class ToolbarUserComponent implements OnInit {
  isUserOpen: boolean;
  isCompanyOpen: boolean;
  userName = '';
  companyName = '';
  companyParentId: number;
  languages = [];
  imagePath: SafeResourceUrl;
  companies: CompanyInfo[];

  constructor(public languageService: LanguageService, private securityService: SecurityService) {}

  ngOnInit() {
    setTimeout(() => {
      const user = this.securityService?.getUser();
      if (user) {
        this.userName = user.name;
        this.companyName = user.companyName;
        this.companyParentId = user.companyParentId;
        this.companies = user.companies;
      }
      this.imagePath = this.securityService.getUserAvi();
    }, 1000)
  }

  private getLanguages() {
    this.languageService.getLanguages().subscribe((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          this.languages.push(data[i]);
        }
      }
    });
  }

  saveLanguage(language: any) {
    this.saveAsyncLanguage(language);
  }

  private saveAsyncLanguage(language: any) {
    const userId = this.securityService.getUserId();
    this.languageService.saveLanguageDefault(userId, language.id).subscribe(() => {
      this.securityService.setLanguageDefault(language.alias);
      this.languageService.reload();
    });
  }

  signout() {
    this.securityService.signout();
  }

  toggleUserDropdown() {
    this.isUserOpen = !this.isUserOpen;
  }

  toggleCompanyDropdown() {
    this.isCompanyOpen = !this.isCompanyOpen;
  }

  onUserClickOutside() {
    this.isUserOpen = false;
  }

  onCompanyClickOutside() {
    this.isCompanyOpen = false;
  }

  isCompanyParent() {
    return this.companyParentId !== undefined && this.companyParentId !== null && this.companyParentId > 0;
  }

  changeCompany(company: CompanyInfo) {
    this.isCompanyOpen = !this.isCompanyOpen;
    this.companyName = company.companyName;
    this.companyParentId = company.companyParentId;    
    this.securityService.updateUserCompany(company);
  }
}
