import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'onboarding-modal-exceptions',
  templateUrl: './modal-exceptions.component.html',
  styleUrls: ['./modal-exceptions.component.scss']
})
export class ModalExceptionsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dataModal: string) { }
  message = ''
  title = ''

  ngOnInit(): void {
    this.getTextModal();
  }


  getTextModal() {
    if (this.dataModal === 'upload_file') {
      this.message = 'Somente arquivos em PNG, JPG, JPEG!',
        this.title = 'Erro ao realizar o upload'
    } else {
      this.title = 'Erro ao realizar a requisição'
    }
  }

}
