import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'snack-bar-component',
    templateUrl: 'snack-bar.component.html',
    styleUrls: ['snack-bar.component.scss'],
})
export class SnackbarComponent {
    message = '';
    constructor(
        @Inject(MAT_SNACK_BAR_DATA)
        dialogData: string
    ) {
        this.message = dialogData;
    }
}