import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private auth: AuthService, 
    private appSettings: AppSettingsService,
  ) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      window.location.href = this.appSettings.getHiperstream3();
      return false;
    }
    return true;
  }
}
