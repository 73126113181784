import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../material-components.module";
import { ModalExceptionsComponent } from "./modal-exceptions.component";

@NgModule({
imports: [
    CommonModule, 
    MaterialModule,
],
declarations: [ModalExceptionsComponent], 
exports: [ModalExceptionsComponent],

})

export class ModalExceptionModule { }