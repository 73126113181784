import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../shared/snackbar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar, private zone: NgZone) { }

  showSuccess(message: string): void {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: message,
        duration: 2000,
      });
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: ['error'], 
        data: message,
        duration: 2000, 
      });
    });
  }
}
